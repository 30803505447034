import {
    getRunData, getRunDataByRunId, getSignalPlotsData, getHistogramPlotsData, getTileAreaAsync, getCirclePlotsData, getRunConfigurationTableData, getPipelineVersionData, getGitRevsData, getPNGData, checkRunIsBarcodedAsync, getBeadsFilteringTableDataAsync,
    getBarcodeStatisticsTableDataAsync, getBERTableDataAsync, getBERHMERTableDataAsync, getRLQTableDataAsync, getFMetricsTableDataAsync, getJSONData, getQTableDataAsync, getBaseCallingReportAsync, getSignalsLinearitTableDataAsync, getOpticsPlotsData, getHistogramPlotsByFlowData,
    getClumpingsTableDataAsync, getHealthPlotsTemperatureDataAsync, getHealthPlotsHumidityDataAsync, getPhasingTableDataAsync, getCovGCBiasTableDataAsync, checkVCReportExistsAsync, getRunDataByRowCount, getRunDataByDate, getAMPData, getAMPPlotsData, getOpticsPlotsLaserData, getImgDeformData,
    getBeadLossData, getSAMReportAsync, getFWHMData, getFWHMDataMany, getTemplateReport, getSupplementReport, getLateFlowMetricsData, getNoiseAndContextData, postAnalysisCommentData, postDetailsData, getH5BerPerFlowPlotData, getAnalysisMetaDataAsync, getVersionsAsync, getH5ReadLengthPlotData,
    getH5MedianRSQPlotData, getBCALLJSONData, getHistogramPlotsDataAll, getReadsPassFilterAsync, getH5ReadsFilteringPlotData, getH5BerPerReadHistPlotData, checkTemplateReportExist, checkBaseCallingReportExistAsync, getRunDataGrouped, getHomoIndelErrorAsync, getH5FlowErrorPlotData,
    getH5PolyPlotDataAsync, getFWHMCSVDataAsync, getAmpRunCSV, getRunGroupedAllValidated, getAMPValidatedData, getH5PolyPlotDataValidatedAsync, getRunValidatedDataByRunId, getFwhmDataValidatedAsync, getRunDataAsyncValidated, getPipeLineDataAsyncValidated,
    getGitRevDataAsyncValidated, getAnalysisMetaDataAsyncValidated, getVersionsDataAsyncValidated, getReadPeadsFilteringDataAsyncValidated, getH5BerFlowPlotDataValidated, getH5BerHistPlotDataValidated, getH5ReadLengthPlotDataValidated, getH5FlowErrorPlotDataValidated, getPhasingDataAsyncValidated,
    getH5ReadsFilteringPlotDataValidated, getH5MedianRSQPlotDataValidated, getBarcodeStatisticsTableDataAsyncValidated, getClumpingDataAsyncValidated, getLinearityDataAsyncValidated, getBeadsFilteringDataAsyncValidated, getBerDataAsyncValidated, getHmerDataAsyncValidated, getRLQDataAsyncValidated,
    getFMetricsDataAsyncValidated, getQTableDataAsyncValidated, getRunInfoDataAsync, getDiskSpaceDataAsync, deleteRunsApplyDataAsync, getCalculatedFreeSpaceeDataAsync, getSignalPlotsDataAsyncValidated, getRunIdDataByDate, deleteRunIdsDataAsync,
    getPpmSeqReportListDataAsync, getDeletionStatusAsync, getRunInfoLengthDataAsync

} from "../../services/apiService";
import {
    LOAD_RUN_LIST_DATA, SET_CURRENT_RUN_DATA, SET_IS_LOADING, GET_SIGNAL_PLOTS_DATA, SET_SIGNAL_PLOTS_RENDER_DATA, GET_HISTOGRAM_PLOTS_DATA, SET_HISTOGRAM_PLOTS_RENDER_DATA, SET_FLOW, SET_TILE_AREA, SET_INSTRUMENT_ID, SET_RUN_ID, GET_CIRCLE_PLOTS_DATA, SET_CIRCLE_PLOTS_RENDER_DATA, SET_IS_SIGNAL_PLOTS_LOADING, SET_IS_HISTOGRAM_PLOTS_LOADING,
    SET_DENSITY_SPINNER, SET_SIGNAL_SPINNER, SET_SNR_SPINNER, SET_STATS_TABLE_DATA, SET_THETA_PROFILING_PLOTS_RENDER_DATA, SET_THETA_PROFILING_PLOTS_RADIUS, GET_RUN_CONFIGURATION_TABLE_DATA, SET_RUN_CONFIGURATION_TABLE_RENDER_DATA, GET_RUN_CONFIGURATION_PLOTS, CHECK_RUN_IS_BARCODED, GET_BEADS_FILTERING_TABLE_DATA,
    SET_BEADS_FILTERING_TABLE_RENDER_DATA, GET_BEADS_FILTERING_PLOTS, GET_BARCODE_STATISTICS_TABLE_DATA, SET_BARCODE_STATISTICS_TABLE_RENDER_DATA, GET_BER_TABLE_DATA, SET_BER_TABLE_RENDER_DATA, GET_BER_HMER_TABLE_DATA, SET_BER_HMER_TABLE_RENDER_DATA, GET_ERROR_PLOTS, GET_RLQ_TABLE_DATA, SET_RLQ_TABLE_RENDER_DATA,
    GET_FMETRICS_TABLE_DATA, SET_FMETRICS_TABLE_RENDER_DATA, GET_BARCODE_COVERAGE_PLOTS, GET_BASE_QUALITY_PLOTS, GET_PHASING_PLOTS, GET_SIGNALS_PLOTS, GET_COVERAGE_GC_PLOTS, GET_UNIFORMITY_PLOTS, GET_UNIFORMITY_SIGNAL_PLOTS, GET_Q_TABLE_DATA, SET_Q_TABLE_RENDER_DATA, GET_HG_BASECALLING_REPORT, GET_EC_BASECALLING_REPORT,
    GET_SIGNALS_LINEARITY_TABLE_DATA, SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA, GET_SIGNALS_LINEARITY_PLOTS, GET_OPTICS_PLOTS_DATA, SET_OPTICS_PLOTS_RENDER_DATA, GET_BEAD_LOSS_PLOTS_DATA, GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA, SET_DENSITY_PLOTS_RENDER_DATA, SET_STATS_DENSITY_TABLE_DATA, GET_CLUMPINGS_TABLE_DATA, SET_CLUMPINGS_TABLE_RENDER_DATA,
    SET_IS_RUN_DATA_LOADING, SET_USER, GET_HEALTH_DATA_TEMPERATURE_PLOTS, SET_HEALTH_DATA_IS_LOADING, SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA, GET_HEALTH_DATA_HUMIDITY_PLOTS, SET_HEALTH_DATA_HUMIDITY_RENDER_DATA, GET_COVERAGE_PLOTS, GET_PHASING_TABLE_DATA, GET_PHASING_TABLE_RENDER_DATA,
    GET_COVGCBIAS_TABLE_DATA, GET_COVGCBIAS_TABLE_RENDER_DATA, CHECK_VCREPORT_EXIST, GET_VCREPORT_PLOTS, IS_MAIN_TABLE_FIRST_PAGE_LOADED, SET_ACTIVE_TAB_NAME, SET_RUN_IS_BARCODED, IS_NUM_FLOWS_100_LOADED, LOAD_AMP_LIST_DATA, SET_IS_AMP_DATA_LOADING, SET_AMP_RUN_ID, SET_CURRENT_AMP_RUN_DATA, GET_AMP_PLOTS_DATA, SET_IS_AMP_PLOTS_DATA_LOADING,
    SET_IS_OPTICS_PLOTS_DATA_LOADING, SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME, SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, GET_OPTICS_PLOTS_LASER_DATA, SET_OPTICS_PLOTS_LASER_RENDER_DATA, GET_IMG_DEFORM_DATA, SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, SET_IMG_DEFORM_RENDER_DATA, GET_BEAD_LOSS_PLOTS_DATA_CSV, SET_BEAD_LOSS_DATA_LOADING,
    GET_SAM_REPORT, SET_SAM_REPORT_IS_LOADING, GET_FWHM_PLOTS_DATA, SET_FWHM_PLOTS_IS_LOADING, GET_FWHM_PLOTS_CAMERA_DATA, SET_TEMPLATE_REPORT_IS_LOADING, GET_TEMPLATE_REPORT, SET_TEMPLATE_REPORT_RENDER_DATA, SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME, GET_SUPPLEMENTARY_REPORT, SET_SUPPLEMENTARY_REPORT_IS_LOADING, SET_SUPPLEMENTARY_REPORT_RENDER_DATA,
    SET_LATE_FLOW_METRICS_IS_LOADING, GET_LATE_FLOW_METRICS_DATA, GET_TOOL_NOISE_PLOTS, SET_TOOL_NOISE_PLOTS_IS_LOADING, GET_TOOL_NOISE_TABLE_DATA, SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, SET_CUSTOM_FILTERS, SET_CUSTOM_FILTERS_APPLIED, SET_RUN_LIST_DATA_FILTERED, SET_RUN_LIST_DATA, SET_RUN_LIST_DOWNLOAD_DATA, SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100,
    SET_ANALYSIS_COMMENT_DATA_SAVED, SET_DETAILS_DATA_SAVED, SET_ISAUTHENTICATED, LOGOUT_CURRENT_USER, RESET_STATE, SET_EDIT_MODE, SET_RUN_LIST_DATA_SEARCHED, SET_RUN_LIST_DATA_SEARCHED_AMP, SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED, SET_SEARCH_TERM, SET_SEARCH_TERM_AMP, SET_RUN_LIST_AMP_DOWNLOAD_DATA, IS_EDIT_ALLOWED, SET_ERROR_PLOT_IS_LOADING,
    SET_READ_LENGTH_PLOT_IS_LOADING, GET_READ_LENGTH_PLOTS, SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE, SET_ERROR_PLOTS_BY_CAMERA_VALUE, GET_RSQ_PLOT, SET_RSQ_PLOT_IS_LOADING, GET_POLY_PLOTS, SET_POLY_PLOTS_IS_LOADING, SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, GET_FWHM_PLOTS, SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA,
    GET_RUN_SUMMARY_DENSITY_PLOT_DATA, SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING, RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE, SET_VERSION, SET_BEADS_FILTERING_PLOTS_IS_LOADING, READS_FILTERING_PLOT_BY_CAMERA_VALUE, SET_FMETRICS_DATA_IS_LOADING, GET_COVERAGE_PLOTS_DATA, GET_COVERAGE_PLOTS_DATA_IS_LOADING, SET_TEMPLATE_REPORT_EXIST,
    SET_HG_BASECALLING_REPORT_EXIST, SET_EC_BASECALLING_REPORT_EXIST, GET_CHART_VIEW_PLOTS_DATA, SET_CHART_VIEW_PLOTS_DATA_IS_LOADING, GET_SIGNALS_PLOTS_PNG, GET_BASE_QUALITY_BER_PER_FLOW_PLOT, SET_ALERT, CLEAR_ALERT, SET_IS_UNIFIED_ROW_SELECTED, GET_HOMO_INDEL_ERROR, SET_POLY_PLOTS_BY_CAMERA_VALUE,
    ADD_PLOTLY_PLOT_TO_LIST, GET_FWHM_CSV_DATA, GET_AMP_SINGLE_RUN_DATA, SET_IS_AMP_SINGLE_RUN_LOADING, SET_BY_CAMERA_VALUE, SET_SHOW_FROZEN_HEADER, SET_JWT, SET_JWT_EXP, SET_CALCULATE_COVERAGE_DATA, GET_RUNINFO_DATA, GET_DISKSPACE, SET_CUSTOM_STORAGE, SET_ALERT_STORAGE_RUN, DELETE_RUN_REQUEST_STATUS, CALCULATED_CUSTOM_STORAGE_SPACE,
    IS_VALID_DATA_LOADING, LOAD_H5PLOT_VALID_DATA, GET_RLQ_TABLE_VALIDATED, SINGLE_VALID_RUN_DATA, VALIDATION_STATUS, LOAD_VALID_DATA, GET_LINEARITY_VALIDATED, LOAD_VALID_AMP_DATA, GET_FMETRICS_TABLE_VALIDATED, GET_RUN_CONFIGURATION_VALIDATED_DATA, GET_READS_FILTERING_PLOTS_VALIDATED, GET_ERROR_PLOTS_VALIDATED, GET_RSQ_PLOT_VALIDATED,
    SET_VALID_DATA, SET_H5PLOTS_VALID_DATA, FWHM_VALID_RUN_DATA, GET_PHASING_VALIDATED_DATA, GET_VALIDATED_READ_LENGTH_PLOTS, GET_BARCODE_STATISTICS_VALIDATED, ADD_BEADS_FFILTER_TABLE_PDF, GET_BEADS_FILTERING_VALIDATED, GET_BER_TABLE_VALIDATED, GET_CLUMPINGS_VALIDATED, GET_BER_HMER_TABLE_VALIDATED, GET_Q_TABLE_VALIDATED,
    ACTIVE_BARCODE_TABLE_BUTTON, ADD_PLOTLY_URI_LIST, ADD_RUN_DETAILS_TABLE_PDF, GET_SIGNAL_DATA_VALIDATED, TIME_ZONE_SELECTED, POPULATE_RUNIDS, SET_IS_IMPORT_FIELD_LOADING, GET_PPMSEQ_REPORT_LIST, SET_COLUMN_TYPE, SET_BARCODE_COVERAGE_BARCODE, SET_EXPANDED_RUNID, CLEAR_EXPANDED_RUNID
} from "../constants/action-types";
import * as Utils from '../../utils/utils';

import AuthAPI from '../auth/api';

export const setUser = (user) => {
    return async dispatch => {
        dispatch({ type: SET_USER, payload: user });
    };
}

export const setNumFlows100Loaded = (isLoaded) => {
    return async dispatch => {
        dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: isLoaded });
    };
}

export const setAmpPlotsDataLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_AMP_PLOTS_DATA_LOADING, payload: isLoading });
    };
}

export const setOpticsPlotsDataLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: isLoading });
    };
}

export const setActiveTabName = (name) => {
    return async dispatch => {
        dispatch({ type: SET_ACTIVE_TAB_NAME, payload: name });
    };
}

export const setActiveInstrHealthSubTabName = (name) => {
    return async dispatch => {
        dispatch({ type: SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME, payload: name });
    };
}

export const setActiveTemplateReportSubTabName = (name) => {
    return async dispatch => {
        dispatch({ type: SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME, payload: name });
    };
}

export const setCurrentRunData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_CURRENT_RUN_DATA, payload: data });
    };
}

export const setCurrentAMPRunData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_CURRENT_AMP_RUN_DATA, payload: data });
    };
}

export const getAMPDataAsync = (jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_AMP_DATA_LOADING, payload: true });
        await getAMPData(jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: LOAD_AMP_LIST_DATA, payload: json });
            dispatch({ type: SET_IS_AMP_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_AMP_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_AMP_DATA_LOADING, payload: false });
        });
    };
}

export const getAmpSingleRunCSV = (runid, jwt) => {
    return async (dispatch) => {
        dispatch({ type: SET_IS_AMP_SINGLE_RUN_LOADING, payload: true });
        await getAmpRunCSV(runid, jwt)
            .then((response) => {
                if (!response.ok) throw Error(response.statusText);
                return response.text();
            })
            .then((csvData) => {
                try {
                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `AMPHealth_${runid}.csv`);
                    link.setAttribute('id', 'downloadLink');
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    dispatch({ type: GET_AMP_SINGLE_RUN_DATA, payload: csvData, runid: runid });
                    dispatch({ type: SET_IS_AMP_SINGLE_RUN_LOADING, payload: false });
                }
                catch {
                    dispatch({ type: GET_AMP_SINGLE_RUN_DATA, payload: undefined, runid: runid });
                }
            })
            .catch(() => {
                dispatch({ type: GET_AMP_SINGLE_RUN_DATA, payload: undefined, runid: runid });
                dispatch({ type: SET_IS_AMP_SINGLE_RUN_LOADING, payload: false });
            })
            .finally(() => {
                dispatch({ type: SET_IS_AMP_SINGLE_RUN_LOADING, payload: false });
            });
    };
};

export const getAMPPlotsDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_AMP_PLOTS_DATA_LOADING, payload: true });
        await getAMPPlotsData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_AMP_PLOTS_DATA, payload: json, runid: runid });
            dispatch({ type: SET_IS_AMP_PLOTS_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_AMP_PLOTS_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_AMP_PLOTS_DATA_LOADING, payload: false });
        });
    };
}

export const getRunDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: false });
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        await getRunDataByRunId(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            let sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort((a, b) => {
                return new Date(b.startdatetime) - new Date(a.startdatetime);
            });
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: sorted });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: true });
        }).catch(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}

export const getRunDataAllAsync = (numFlows, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });

        await getRunData(numFlows, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            let sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort((a, b) => {
                return new Date(b.startdatetime) - new Date(a.startdatetime);
            });
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: sorted });
            if (numFlows === undefined) dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: true });
            else dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}


export const getRunDataAllGroupedAsync = (numFlows, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        await getRunDataGrouped(numFlows, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            let sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort((a, b) => {
                return new Date(b.startdatetime) - new Date(a.startdatetime);
            });
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: sorted });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            if (numFlows === undefined) dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: true });
            else dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}

export const getRunDataByNumFlowsAsync = (numFlows, jwt) => {
    return async dispatch => {
        await getRunData(numFlows, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            let sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort((a, b) => {
                return new Date(b.startdatetime) - new Date(a.startdatetime);
            });
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: sorted });
            if (!numFlows) dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_NUM_FLOWS_100_LOADED, payload: false });
        }).catch(() => {
        });
    };
}

export const getRunDataAllByRowCountAsync = (rowCount, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: false });
        await getRunDataByRowCount(rowCount, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: json });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: true });
            if (json && json.length >= 1) dispatch({ type: SET_INSTRUMENT_ID, payload: json[0].sysid });
        }).catch(() => {
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: [] });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}

export const getRunDataAllByDateAsync = (start_date, end_date, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: false });
        await getRunDataByDate(start_date, end_date, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: json });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: true });
        }).catch(() => {
            dispatch({ type: LOAD_RUN_LIST_DATA, payload: [] });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}

export const setIsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_LOADING, payload: isLoading });
    };
}

export const setIsMainTableFirstPageLoaded = (isLoaded) => {
    return async dispatch => {
        dispatch({ type: IS_MAIN_TABLE_FIRST_PAGE_LOADED, payload: isLoaded });
    };
}

export const setIsRunDataLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: isLoading });
    };
}

export const getSignalPlotsDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_SIGNAL_PLOTS_LOADING, payload: true });
        await getSignalPlotsData(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_SIGNAL_PLOTS_DATA, payload: json, runid: runid });
            dispatch({ type: SET_IS_SIGNAL_PLOTS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_SIGNAL_PLOTS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_SIGNAL_PLOTS_LOADING, payload: false });
        });
    };
}

export const setSignalPlotsRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_SIGNAL_PLOTS_RENDER_DATA, payload: data, runid: runid });
    };
}

export const getHistogramPlotsDataAsync = (runid, flow, instrId, tileArea, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: true });
        let promises = [];
        let data = getHistogramPlotsData(runid, flow, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json && json.length) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        promises.push(data);

        if (tileArea !== undefined && tileArea !== null) {
            dispatch({ type: SET_TILE_AREA, payload: tileArea, runid: runid, instrId: instrId });
        }
        else {
            let ta = getTileAreaAsync(runid, instrId, jwt).then(resp => {
                if (!resp.ok) throw Error(resp.statusText);
                return resp.json();
            }).then(tileArea => {
                return tileArea;
            }).catch(() => {
                return undefined;
            });
            promises.push(ta);
        }

        Promise.all(promises).then(results => {
            if (results !== undefined && results.length > 0) {
                if (results[0] !== undefined) {
                    dispatch({ type: GET_HISTOGRAM_PLOTS_DATA, payload: results[0], runid: runid, flow: flow });
                }
                else dispatch({ type: GET_HISTOGRAM_PLOTS_DATA, payload: undefined, runid: runid, flow: flow });
                if (results[1] !== undefined) dispatch(setTileArea(results[1], runid, instrId));
            }
            dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: false });
        });
    };
}

export const setHistogramPlotsRenderData = (data, runid, flow) => {
    return async dispatch => {
        dispatch({ type: SET_HISTOGRAM_PLOTS_RENDER_DATA, payload: data, runid: runid, flow: flow });
    };
}

export const setFlow = (flow) => {
    return async dispatch => {
        dispatch({ type: SET_FLOW, payload: flow });
    };
}

export const setTileArea = (tile_area, runid, instrId) => {
    return async dispatch => {
        dispatch({ type: SET_TILE_AREA, payload: tile_area, runid: Utils.getRunIdWithoutCamera(runid), instrId: instrId });
    };
}

export const setInstrumentId = (instrId) => {
    return async dispatch => {
        dispatch({ type: SET_INSTRUMENT_ID, payload: instrId });
    };
}

export const setRunId = (runId) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_ID, payload: runId });
    };
}

export const setAMPRunId = (runId) => {
    return async dispatch => {
        dispatch({ type: SET_AMP_RUN_ID, payload: runId });
    };
}

export const getCirclePlotsDataAsync = (runid, flow, jwt) => {
    return async dispatch => {
        await getCirclePlotsData(runid, flow, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_CIRCLE_PLOTS_DATA, payload: json, runid: runid });
            else dispatch({ type: GET_CIRCLE_PLOTS_DATA, payload: [], runid: runid });
        }).catch(() => { });
    };
}

export const setCirclePlotsRenderData = (data, runid, flow) => {
    return async dispatch => {
        dispatch({ type: SET_CIRCLE_PLOTS_RENDER_DATA, payload: data, runid: runid, flow: flow });
    };
}

export const setIsSignalPlotsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_SIGNAL_PLOTS_LOADING, payload: isLoading });
    };
}

export const setIsHistogramPlotsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: isLoading });
    };
}

export const setSpinnerDensity = (spinner) => {
    return async dispatch => {
        dispatch({ type: SET_DENSITY_SPINNER, payload: spinner });
    };
}

export const setSpinnerSignal = (spinner) => {
    return async dispatch => {
        dispatch({ type: SET_SIGNAL_SPINNER, payload: spinner });
    };
}

export const setSpinnerSNR = (spinner) => {
    return async dispatch => {
        dispatch({ type: SET_SNR_SPINNER, payload: spinner });
    };
}

export const setStatsTable1Data = (data, runid, flow) => {
    return async dispatch => {
        dispatch({ type: SET_STATS_TABLE_DATA, payload: data, runid: runid, flow: flow });
    };
}

export const setStatsTableDataDensity = (data) => {
    return async dispatch => {
        dispatch({ type: SET_STATS_DENSITY_TABLE_DATA, payload: data });
    };
}

export const setThetaProfilingPlotsRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_THETA_PROFILING_PLOTS_RENDER_DATA, payload: data, runid: runid });
    };
}

export const setThetaProfilingPlotsRadius = (radius, runid) => {
    return async dispatch => {
        dispatch({ type: SET_THETA_PROFILING_PLOTS_RADIUS, payload: radius, runid: runid });
    };
}

export const getRunConfigTableDataAsync = (runid, flow, jwt) => {
    return async dispatch => {
        let rc = getRunConfigurationTableData(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let pv = getPipelineVersionData(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let gr = getGitRevsData(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let am = getAnalysisMetaDataAsync(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let v = getVersionsAsync(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let rm = getReadsPassFilterAsync(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        Promise.all([rc, pv, gr, am, v, rm]).then(results => {
            try {
                if (results !== undefined && results.length >= 6) {
                    let result = [];
                    if (results[0] !== undefined && !Utils.isObjectEmpty(results[0])) {
                        result.push(results[0]);
                    }
                    if (results[1] !== undefined && !Utils.isObjectEmpty(results[1])) {
                        result.push(results[1]);
                    }
                    if (results[2] !== undefined && !Utils.isObjectEmpty(results[2])) {
                        result.push(results[2])
                    }
                    if (results[3] !== undefined && !Utils.isObjectEmpty(results[3])) {
                        result.push(results[3])
                    }
                    if (results[4] !== undefined) {
                        result.push(results[4])
                    }
                    if (results[5] !== undefined) {
                        result.push(results[5])
                    }
                    dispatch({ type: GET_RUN_CONFIGURATION_TABLE_DATA, payload: result, runid: runid, flow: flow });
                }
                else dispatch({ type: GET_RUN_CONFIGURATION_TABLE_DATA, payload: undefined, runid: runid, flow: flow });
            }
            catch (e) {
                dispatch({ type: GET_RUN_CONFIGURATION_TABLE_DATA, payload: undefined, runid: runid, flow: flow });
            }
        }).catch(() => dispatch({ type: GET_RUN_CONFIGURATION_TABLE_DATA, payload: undefined, runid: runid, flow: flow }));
    };
}

export const setRunConfigTableRenderData = (data, runid, camera) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_CONFIGURATION_TABLE_RENDER_DATA, payload: data, runid: runid, camera: camera });
    };
}

export const getRunConfigurationPlots = (runid, imgName, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: true });
        let pngs = getPNGData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                let result = [];
                if (json && json.length > 0) {
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                }
                return result;
            }
            catch (error) {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        let h5 = getH5MedianRSQPlotData(runid, 'wafer_map_rsq', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5Data => {
            return h5Data;
        }).catch(() => {
            return undefined;
        });

        Promise.all([pngs, h5]).then(results => {
            try {
                let result = { plot1: undefined, plot2: undefined };
                if (results !== undefined && results.length >= 2) {
                    if (results[1] !== undefined) result.plot2 = results[1];
                    if (results[0] !== undefined && results[0].length >= 0) {
                        result.plot1 = results[0][1];
                        if (results[1] === undefined && results[0].length >= 2) result.plot2 = results[0][1];
                    }
                }
                dispatch({ type: GET_RUN_CONFIGURATION_PLOTS, payload: result, runid: runid });
            }
            catch {
                dispatch({ type: GET_RUN_CONFIGURATION_PLOTS, payload: { plot1: undefined, plot2: undefined }, runid: runid });
            }
        }).catch(() => {
            dispatch({ type: GET_RUN_CONFIGURATION_PLOTS, payload: { plot1: undefined, plot2: undefined }, runid: runid });
        }).finally(() => {
            dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: false });
        });
    };
}

export const checkRunIsBarcoded = (runid, jwt) => {
    return async dispatch => {
        await checkRunIsBarcodedAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(isBarcoded => {
            dispatch({ type: CHECK_RUN_IS_BARCODED, payload: isBarcoded, runid: runid });
        }).catch(() => { });
    };
}

export const setRunIsBarcoded = (runid, isRunBarcoded) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_IS_BARCODED, payload: isRunBarcoded, runid: runid });
    };
}

export const getBeadsFilteringTableData = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getBeadsFilteringTableDataAsync(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BEADS_FILTERING_TABLE_DATA, payload: json, runid: runid });
        }).catch(() => {
            dispatch({ type: GET_BEADS_FILTERING_TABLE_DATA, payload: undefined, runid: runid });
        }).finally(() => { });
    };
}

export const setBeadsFilteringTableRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_BEADS_FILTERING_TABLE_RENDER_DATA, payload: data, runid: runid });
    };
}

export const getBeadsFilteringPlots = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_BEADS_FILTERING_PLOTS_IS_LOADING, payload: true });
        let pngs = getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return { type: 'png', data: { plot1: result[0], plot2: result[1] } };
                }
                else return { type: 'png', data: undefined };
            }
            catch (error) {
                return { type: 'png', data: undefined };
            }
        }).catch(() => {
            return { type: 'png', data: undefined };
        });

        let h5Data = getH5ReadsFilteringPlotData(runid, 'reads_counting_data_passed_quality', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            try {
                if (h5 && Object.keys(h5).length !== 0 && h5.constructor === Object) return { type: 'h5', data: h5 }
                else return { type: 'h5', data: undefined };
            }
            catch {
                return { type: 'h5', data: undefined };
            }
        }).catch(() => {
            return { type: 'h5', data: undefined };
        });

        Promise.all([pngs, h5Data]).then(results => {
            let result;
            if (results !== undefined && results.length >= 2) {
                if (results[1] !== undefined && results[1].data !== undefined) result = results[1];
                else if (results[0] !== undefined) result = results[0];
            }
            dispatch({ type: GET_BEADS_FILTERING_PLOTS, runid: runid, payload: result, camera: camera });
            dispatch({ type: SET_BEADS_FILTERING_PLOTS_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_BEADS_FILTERING_PLOTS_IS_LOADING, payload: false });
            dispatch({ type: GET_BEADS_FILTERING_PLOTS, runid: runid, payload: undefined, camera: camera });
        }).finally(() => {
            dispatch({ type: SET_BEADS_FILTERING_PLOTS_IS_LOADING, payload: false });
        });
    };
}

export const getBarcodeStatisticsTableData = (runid, jwt) => {
    return async dispatch => {
        await getBarcodeStatisticsTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BARCODE_STATISTICS_TABLE_DATA, payload: json, runid: runid });
        }).catch(() => {
            dispatch({ type: GET_BARCODE_STATISTICS_TABLE_DATA, payload: undefined, runid: runid });
        });
    };
}

export const setBarcodeStatisticsTableRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_BARCODE_STATISTICS_TABLE_RENDER_DATA, payload: data, runid: runid });
    };
}

export const getBERTableData = (runid, jwt) => {
    return async dispatch => {
        await getBERTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BER_TABLE_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setBERTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_BER_TABLE_RENDER_DATA, payload: data });
    };
}

export const getBERMERTableData = (runid, jwt) => {
    return async dispatch => {
        await getBERHMERTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BER_HMER_TABLE_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setBERHMERTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_BER_HMER_TABLE_RENDER_DATA, payload: data });
    };
}

export const getErrorPlots = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        let h5Plot1 = getH5BerPerReadHistPlotData(runid, 'ber_per_read_hist', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let h5Plot2 = getH5BerPerFlowPlotData(runid, 'ber_per_flow', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let pngs = getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return { plot1: result[0], plot2: result[1] };
                }
                else return undefined;
            }
            catch {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        Promise.all([h5Plot2, pngs, h5Plot1]).then(results => {
            try {
                let result = { plot1: undefined, plot2: undefined };
                if (results !== undefined && results.length >= 3) {
                    if (results[0] !== undefined) result.plot2 = results[0];
                    if (results[2] !== undefined) result.plot1 = results[2];
                    else if (results[1] !== undefined) {
                        result.plot1 = results[1].plot1;
                        if (!results[0] || (results[0] && Object.keys(results[0]).length === 0 && Object.getPrototypeOf(results[0]) === Object.prototype)) {
                            result.plot2 = results[1].plot2;
                        }
                    }
                }
                dispatch({ type: GET_ERROR_PLOTS, runid: runid, camera: camera, payload: result });
            }
            catch {
                dispatch({ type: GET_ERROR_PLOTS, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined } });
            }
        }).catch(() => dispatch({ type: GET_ERROR_PLOTS, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined } }));
    };
}

export const getRLQTableData = (runid, jwt) => {
    return async dispatch => {
        await getRLQTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_RLQ_TABLE_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setRLQTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RLQ_TABLE_RENDER_DATA, payload: data });
    };
}

export const getFMetricsTableData = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_FMETRICS_DATA_IS_LOADING, payload: true });
        await getFMetricsTableDataAsync(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_FMETRICS_TABLE_DATA, payload: json });
        }).catch(() => {
        }).finally(() => {
            dispatch({ type: SET_FMETRICS_DATA_IS_LOADING, payload: false });
        });
    };
}

export const setFMetricsTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_FMETRICS_TABLE_RENDER_DATA, payload: data });
    };
}

export const setBarcodeCoveragePlotIsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, payload: isLoading });
    };
}

export const geBarcodeCoveragePlots = (runid, imgName, jsonName, barcode, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, payload: true });
        let pngs = getPNGData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return result;
                }
            }
            catch {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        let jsons = getBCALLJSONData(runid, jsonName, barcode, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([pngs, jsons]).then(results => {
            let result = {};
            try {
                if (results !== undefined && results.length >= 0) {
                    if (results[0] !== undefined) result = { type: 'from_file', data: results[0] };
                    else if (results[1] !== undefined) result = { type: 'from_db', data: results[1] };
                }
                const barcodeData = { barcode: barcode, data: result };
                dispatch({ type: GET_BARCODE_COVERAGE_PLOTS, runid: runid, payload: { data: [barcodeData], type: result.type } });
                dispatch({ type: SET_CALCULATE_COVERAGE_DATA, runid: runid, payload: result.data });
                dispatch({ type: SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, payload: false });
            }
            catch {
                dispatch({ type: GET_BARCODE_COVERAGE_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_BARCODE_COVERAGE_PLOTS, runid: runid, payload: undefined });
        }).finally(() => dispatch({ type: SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, payload: false }));
    };
}

export const geBaseQualityPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        try {
            let pngs = getPNGData(runid, imgName, false, jwt).then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            }).then(json => {
                try {
                    if (json && json.length > 0) {
                        let result = [];
                        json.map(m => {
                            let buffer = m.data;
                            let binary = '';
                            let bytes = [].slice.call(new Uint8Array(buffer));
                            bytes.forEach((b) => binary += String.fromCharCode(b));
                            result.push(window.btoa(binary));
                        });
                        return result;
                    }
                }
                catch {
                    return undefined;
                }
            }).catch(() => {
                return undefined;
            });

            let jsons = getJSONData(runid, `${imgName}_JSON`, false, jwt).then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            }).then(json => {
                if (json) return json;
                else return undefined;
            }).catch(() => {
                return undefined;
            });

            let jsons_unified = getJSONData(Utils.getRunIdWithoutCamera(runid), `${imgName}_JSON`, false, jwt).then(response => {
                if (!response.ok) throw Error(response.statusText);
                return response.json();
            }).then(json => {
                if (json) return json;
                else return undefined;
            }).catch(() => {
                return undefined;
            });

            Promise.all([pngs, jsons, jsons_unified]).then(results => {
                try {
                    if (results !== undefined && results.length >= 3) {
                        let result = {};
                        if (results[2] !== undefined) result = { type: 'from_db', data: results[2] };
                        else if (results[1] !== undefined) result = { type: 'from_db', data: results[1] };
                        else if (results[0] !== undefined) result = { type: 'from_file', data: results[0] };
                        dispatch({ type: GET_BASE_QUALITY_PLOTS, runid: runid, payload: result });
                    }
                    else dispatch({ type: GET_BASE_QUALITY_PLOTS, runid: runid, payload: undefined });
                }
                catch { }
            }).catch(() => {
                dispatch({ type: GET_BASE_QUALITY_PLOTS, runid: runid, payload: undefined });
            });
        }
        catch { }
    };
}

export const gePhasingPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        let jsons = await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_unified = await getJSONData(Utils.getRunIdWithoutCamera(runid), imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([jsons, jsons_unified]).then(results => {
            try {
                if (results !== undefined && results.length >= 2) {
                    let result = {};
                    if (results[1] !== undefined) result = results[1];
                    else if (results[0] !== undefined) result = results[0];
                    dispatch({ type: GET_PHASING_PLOTS, runid: runid, payload: result });
                }
                else dispatch({ type: GET_PHASING_PLOTS, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_PHASING_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_PHASING_PLOTS, runid: runid, payload: undefined });
        });
    };
}

export const geSignalsPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        let jsons = await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_unified = await getJSONData(Utils.getRunIdWithoutCamera(runid), imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([jsons, jsons_unified]).then(results => {
            try {
                if (results !== undefined && results.length >= 2) {
                    let result = {};
                    if (results[1] !== undefined) result = results[1];
                    else if (results[0] !== undefined) result = results[0];
                    dispatch({ type: GET_SIGNALS_PLOTS, runid: runid, payload: result });
                }
                else dispatch({ type: GET_SIGNALS_PLOTS, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_SIGNALS_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_SIGNALS_PLOTS, runid: runid, payload: undefined });
        });
    };
}

export const geSignalsPlotsPNG = (runid, imgName, jwt) => {
    return async dispatch => {
        getPNGData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_SIGNALS_PLOTS_PNG, runid: runid, payload: result });
                }
                else dispatch({ type: GET_SIGNALS_PLOTS_PNG, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_SIGNALS_PLOTS_PNG, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_SIGNALS_PLOTS_PNG, runid: runid, payload: undefined });
        });
    };
}

export const geCoverageGCPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        let jsons = await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_unified = await getJSONData(Utils.getRunIdWithoutCamera(runid), imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([jsons, jsons_unified]).then(results => {
            try {
                if (results !== undefined && results.length >= 2) {
                    let result = {};
                    if (results[1] !== undefined) result = results[1];
                    else if (results[0] !== undefined) result = results[0];
                    dispatch({ type: GET_COVERAGE_GC_PLOTS, runid: runid, payload: result });
                }
                else dispatch({ type: GET_COVERAGE_GC_PLOTS, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_COVERAGE_GC_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_COVERAGE_GC_PLOTS, runid: runid, payload: undefined });
        });
    };
}

export const getUniformityPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        let jsons = await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_unified = await getJSONData(Utils.getRunIdWithoutCamera(runid), imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_x = await getJSONData(runid, `${imgName}_X`, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => {
            return undefined;
        });

        let jsons_unified_x = await getJSONData(Utils.getRunIdWithoutCamera(runid), `${imgName}_X`, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([jsons, jsons_unified, jsons_x, jsons_unified_x]).then(results => {
            try {
                if (results !== undefined && results.length >= 4) {
                    let result = { U: [], U_X: [] };
                    if (results[1] !== undefined) result.U = results[1];
                    else if (results[0] !== undefined) result.U = results[0];

                    if (results[3] !== undefined) result.U_X.push(...results[3]);
                    else if (results[2] !== undefined) result.U_X.push(...results[2]);
                    dispatch({ type: GET_UNIFORMITY_PLOTS, runid: runid, payload: result });
                }
                else dispatch({ type: GET_UNIFORMITY_PLOTS, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_UNIFORMITY_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_UNIFORMITY_PLOTS, runid: runid, payload: undefined });
        });
    };
}

export const getUniformitySignalPlots = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        let pngs = await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return result;
                }
            }
            catch {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        let pngs_unified = await getPNGData(Utils.getRunIdWithoutCamera(runid), imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return result;
                }
            }
            catch {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        Promise.all([pngs, pngs_unified]).then(results => {
            try {
                if (results !== undefined && results.length >= 2) {
                    let result = {};
                    if (results[1] !== undefined) result = results[1];
                    else if (results[0] !== undefined) result = results[0];
                    dispatch({ type: GET_UNIFORMITY_SIGNAL_PLOTS, runid: runid, payload: result });
                }
                else dispatch({ type: GET_UNIFORMITY_SIGNAL_PLOTS, runid: runid, payload: undefined });
            }
            catch {
                dispatch({ type: GET_UNIFORMITY_SIGNAL_PLOTS, runid: runid, payload: undefined });
            }
        }).catch(() => {
            dispatch({ type: GET_UNIFORMITY_SIGNAL_PLOTS, runid: runid, payload: undefined });
        });
    };
}

export const getQTableData = (runid, jwt) => {
    return async dispatch => {
        await getQTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            let result = { ...json };
            dispatch({ type: GET_Q_TABLE_DATA, payload: result });
        }).catch(() => {
            dispatch({ type: GET_Q_TABLE_DATA, payload: undefined });
        });
    };
}

export const setQTableRenderData = (data, type) => {
    return async dispatch => {
        dispatch({ type: SET_Q_TABLE_RENDER_DATA, payload: { data: data, type: type } });
    };
}

export const getBaseCallingReport = (runid, type, jwt) => {
    return async dispatch => {
        await getBaseCallingReportAsync(runid, type, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) {
                if (type === 'HG') dispatch({ type: GET_HG_BASECALLING_REPORT, payload: json });
                else if (type === 'EC') dispatch({ type: GET_EC_BASECALLING_REPORT, payload: json });
            }
        }).catch(() => { });
    };
}

export const checkBaseCallingReportExist = (runid, type, jwt) => {
    return async dispatch => {
        await checkBaseCallingReportExistAsync(runid, type, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(async json => {
            if (json) {
                if (type === 'HG') dispatch({ type: SET_HG_BASECALLING_REPORT_EXIST, payload: json });
                else if (type === 'EC') dispatch({ type: SET_EC_BASECALLING_REPORT_EXIST, payload: json });

                if (json !== undefined && json === true) {
                    await getBaseCallingReportAsync(runid, type, jwt).then(response => {
                        if (!response.ok) throw Error(response.statusText);
                        return response.json();
                    }).then(json => {
                        if (json) {
                            if (type === 'HG') dispatch({ type: GET_HG_BASECALLING_REPORT, payload: json });
                            else if (type === 'EC') dispatch({ type: GET_EC_BASECALLING_REPORT, payload: json });
                        }
                    }).catch(() => {
                        if (type === 'HG') dispatch({ type: GET_HG_BASECALLING_REPORT, payload: undefined });
                        else if (type === 'EC') dispatch({ type: GET_EC_BASECALLING_REPORT, payload: undefined });
                    });
                }
            }
            else {
                if (type === 'HG') {
                    dispatch({ type: SET_HG_BASECALLING_REPORT_EXIST, payload: false });
                    dispatch({ type: GET_HG_BASECALLING_REPORT, payload: undefined });
                }
                else if (type === 'EC') {
                    dispatch({ type: SET_EC_BASECALLING_REPORT_EXIST, payload: false });
                    dispatch({ type: GET_EC_BASECALLING_REPORT, payload: undefined });
                }
            }
        }).catch(() => {
            if (type === 'HG') {
                dispatch({ type: SET_HG_BASECALLING_REPORT_EXIST, payload: false });
                dispatch({ type: GET_HG_BASECALLING_REPORT, payload: undefined });
            }
            else if (type === 'EC') {
                dispatch({ type: SET_EC_BASECALLING_REPORT_EXIST, payload: false });
                dispatch({ type: GET_EC_BASECALLING_REPORT, payload: undefined });
            }
        });
    };
}

export const getSAMReport = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_SAM_REPORT_IS_LOADING, payload: true });
        await getSAMReportAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_SAM_REPORT, payload: json, runid: runid });
            else dispatch({ type: GET_SAM_REPORT, payload: [], runid: runid });
            dispatch({ type: SET_SAM_REPORT_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_SAM_REPORT_IS_LOADING, payload: false });
        });
    };
}

export const getSignalsLinearityTableData = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getSignalsLinearitTableDataAsync(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_SIGNALS_LINEARITY_TABLE_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setSignalsLinearityTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA, payload: data });
    };
}

export const getSignalsLinearityPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_SIGNALS_LINEARITY_PLOTS, payload: json });
        }).catch(() => {
            dispatch({ type: GET_SIGNALS_LINEARITY_PLOTS, payload: [] });
        });
    };
}

export const getOpticsPlotsDataAsync = (runid, flow, type, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: true });
        await getOpticsPlotsData(runid, flow, type, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_OPTICS_PLOTS_DATA, payload: json, runid: runid, flow: flow, beadsType: type });
            else dispatch({ type: GET_OPTICS_PLOTS_DATA, payload: undefined, runid: runid, flow: flow, beadsType: type });
            dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: GET_OPTICS_PLOTS_DATA, payload: undefined, runid: runid, flow: flow, beadsType: type });
            dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: false });
        });
    };
}

export const getOpticsPlotsLaserDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: true });
        await getOpticsPlotsLaserData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json) dispatch({ type: GET_OPTICS_PLOTS_LASER_DATA, payload: json, runid: runid });
                else dispatch({ type: GET_OPTICS_PLOTS_LASER_DATA, payload: [], runid: runid });
                dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: false });
            }
            catch { }
        }).catch(() => {
            dispatch({ type: GET_OPTICS_PLOTS_LASER_DATA, payload: [], runid: runid, });
            dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: false });
        });
    };
}

export const setOpticsPlotsRenderData = (data, runid, flow, type) => {
    return async dispatch => {
        dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: true });
        dispatch({ type: SET_OPTICS_PLOTS_RENDER_DATA, payload: data, runid: runid, flow: flow, beadsType: type });
        dispatch({ type: SET_IS_OPTICS_PLOTS_DATA_LOADING, payload: false });
    };
}

export const setOpticsPlotsLaserRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: true });
        dispatch({ type: SET_OPTICS_PLOTS_LASER_RENDER_DATA, payload: data, runid: runid });
        dispatch({ type: SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, payload: false });
    };
}

export const getBeadLossPlots = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: true });
        await getBeadLossData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(async data => {
            try {
                if (data) {
                    dispatch({ type: GET_BEAD_LOSS_PLOTS_DATA_CSV, payload: data, runid: runid });
                    dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: false });
                }
                else {
                    await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
                        if (!response.ok) throw Error(response.statusText);
                        return response.json();
                    }).then(json => {
                        try {
                            if (json && json.length > 0) {
                                let result = [];
                                json.map(m => {
                                    let buffer = m.data;
                                    let binary = '';
                                    let bytes = [].slice.call(new Uint8Array(buffer));
                                    bytes.forEach((b) => binary += String.fromCharCode(b));
                                    result.push(window.btoa(binary));
                                });
                                dispatch({ type: GET_BEAD_LOSS_PLOTS_DATA, payload: result, runid: runid });
                                dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: false });
                            }
                        }
                        catch {
                            dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: false });
                        }
                    }).catch(() => {
                        dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: false });
                    });
                }
            }
            catch {
                dispatch({ type: SET_BEAD_LOSS_DATA_LOADING, payload: false });
            }
        });
    };
}

export const getHistogramPlotsDataByFlowAsync = (runid, instrId, flowArr, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: true });
        let data = getHistogramPlotsByFlowData(runid, flowArr, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json && json.length) return json;
            return undefined;
        }).catch(() => {
            return undefined;
        });

        let tilArea = getTileAreaAsync(runid, instrId, jwt).then(resp => {
            if (!resp.ok) throw Error(resp.statusText);
            return resp.json();
        }).then(tileArea => {
            return tileArea;
        }).catch(() => {
            return undefined;
        });

        Promise.all([tilArea, data]).then(results => {
            if (results !== undefined && results.length >= 2) {
                if (results[0] !== undefined) dispatch(setTileArea(results[0], runid, instrId));
                if (results[1] !== undefined) dispatch({ type: GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA, payload: results[1], runid: runid });
            }
            dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_HISTOGRAM_PLOTS_LOADING, payload: false });
        });
    };
}

export const setHistogramPlotsByFlowRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_DENSITY_PLOTS_RENDER_DATA, payload: data, runid: runid });
    };
}

export const getClumpingsTableData = (runid, jwt) => {
    return async dispatch => {
        await getClumpingsTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_CLUMPINGS_TABLE_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setClumpingsTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_CLUMPINGS_TABLE_RENDER_DATA, payload: data });
    };
}

export const getHealthPlotsTemperatureData = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: true });
        await getHealthPlotsTemperatureDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json && json.file && json.file.length > 0) dispatch({ type: GET_HEALTH_DATA_TEMPERATURE_PLOTS, payload: json.file, runid: runid });
            else if (json && json.database && json.database.length > 0) dispatch({ type: GET_HEALTH_DATA_TEMPERATURE_PLOTS, payload: json.database, runid: runid });
            else dispatch({ type: GET_HEALTH_DATA_TEMPERATURE_PLOTS, payload: [], runid: runid });
        }).catch(() => {
            dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
        });
    };
}

export const setHealtDataTemperaturePlotRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: true });
        dispatch({ type: SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA, payload: data, runid: runid });
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
    };
}

export const getHealthPlotsHumidityData = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: true });
        await getHealthPlotsHumidityDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json && json.file && json.file.length > 0) dispatch({ type: GET_HEALTH_DATA_HUMIDITY_PLOTS, payload: json.file, runid: runid });
            else if (json && json.database && json.database.length > 0) dispatch({ type: GET_HEALTH_DATA_HUMIDITY_PLOTS, payload: json.database, runid: runid });
            else dispatch({ type: GET_HEALTH_DATA_HUMIDITY_PLOTS, payload: [], runid: runid });
        }).catch(() => {
            dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
        });
    };
}

export const setHealtDataHumidityPlotRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: true });
        dispatch({ type: SET_HEALTH_DATA_HUMIDITY_RENDER_DATA, payload: data, runid: runid });
        dispatch({ type: SET_HEALTH_DATA_IS_LOADING, payload: false });
    };
}

export const getCoveragePlots = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_COVERAGE_PLOTS, payload: !isRunBarcoded ? { plot1: result[0], plot2: result[1], plot3: result[2] } : { plot1: result[0], plot2: result[1] } });
                }
            }
            catch { }
        }).catch(() => { });
    };
}

export const getPhasingTableData = (runid, jwt) => {
    return async dispatch => {
        await getPhasingTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_PHASING_TABLE_DATA, payload: json });
        }).catch(() => {
            dispatch({ type: GET_PHASING_TABLE_DATA, payload: undefined });
        });
    };
}

export const setPhasingTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: GET_PHASING_TABLE_RENDER_DATA, payload: data });
    };
}

export const getCovGCBiasTableData = (runid, jwt) => {
    return async dispatch => {
        await getCovGCBiasTableDataAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_COVGCBIAS_TABLE_DATA, payload: json });
        }).catch(() => {
            dispatch({ type: GET_COVGCBIAS_TABLE_DATA, payload: undefined });
        });
    };
}

export const setCovGCBiasTableRenderData = (data) => {
    return async dispatch => {
        dispatch({ type: GET_COVGCBIAS_TABLE_RENDER_DATA, payload: data });
    };
}

export const checkVCReportExists = (runid, jwt) => {
    return async dispatch => {
        await checkVCReportExistsAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: CHECK_VCREPORT_EXIST, payload: json });
        }).catch(() => { });
    };
}


export const getVCReportPlots = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_VCREPORT_PLOTS, payload: result });
                }
            }
            catch { }
        }).catch(() => { });
    };
}

export const getImgDeformDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, payload: true });
        await getImgDeformData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_IMG_DEFORM_DATA, payload: json, runid: runid });
            dispatch({ type: SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, payload: false });
        });
    };
}

export const setImgDeformRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_IMG_DEFORM_RENDER_DATA, payload: data, runid: runid });
    };
}

export const getFWHMDataAsync = (runid, byCamera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: true });
        await getFWHMData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (byCamera === true) dispatch({ type: GET_FWHM_PLOTS_CAMERA_DATA, payload: json, runid: runid });
            else dispatch({ type: GET_FWHM_PLOTS_DATA, payload: json, runid: runid });
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        });
    };
}

export const setIsFWHMPlotsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: isLoading });
    };
}

export const getFWHMDataManyAsync = (runids, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: true });
        await getFWHMDataMany(runids, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_FWHM_PLOTS_CAMERA_DATA, payload: json });
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_FWHM_PLOTS_IS_LOADING, payload: false });
        });
    };
}

export const setIsTemplateReportLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: isLoading });
    };
}

export const checkTemplateReportExistAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: true });
        await checkTemplateReportExist(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(async json => {
            if (json !== undefined && json === true) {
                dispatch({ type: SET_TEMPLATE_REPORT_EXIST, payload: true, runid: runid });

                await getTemplateReport(runid, jwt).then(response => {
                    if (!response.ok) throw Error(response.statusText);
                    return response.json();
                }).then(json => {
                    if (json) dispatch({ type: GET_TEMPLATE_REPORT, payload: json, runid: runid });
                    else dispatch({ type: GET_TEMPLATE_REPORT, payload: undefined, runid: runid });
                    dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: false });
                }).catch(() => {
                    dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: false });
                }).finally(() => {
                    dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: false });
                });
            }
            else dispatch({ type: SET_TEMPLATE_REPORT_EXIST, payload: false, runid: runid });
        }).catch(() => {
            dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_TEMPLATE_REPORT_IS_LOADING, payload: false });
        });
    };
}

export const setTemplateReportRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_TEMPLATE_REPORT_RENDER_DATA, payload: data, runid: runid });
    };
}

export const setIsSupplementReportLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_SUPPLEMENTARY_REPORT_IS_LOADING, payload: isLoading });
    };
}

export const getSupplementReportAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_SUPPLEMENTARY_REPORT_IS_LOADING, payload: true });
        await getSupplementReport(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_SUPPLEMENTARY_REPORT, payload: json, runid: runid });
            else dispatch({ type: GET_SUPPLEMENTARY_REPORT, payload: undefined, runid: runid });
            dispatch({ type: SET_SUPPLEMENTARY_REPORT_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_SUPPLEMENTARY_REPORT_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_SUPPLEMENTARY_REPORT_IS_LOADING, payload: false });
        });
    };
}

export const setSupplementReportRenderData = (data, runid) => {
    return async dispatch => {
        dispatch({ type: SET_SUPPLEMENTARY_REPORT_RENDER_DATA, payload: data, runid: runid });
    };
}

export const setIsLateFlowMetricsDataLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_LATE_FLOW_METRICS_IS_LOADING, payload: isLoading });
    };
}

export const getLateFLowMetricsDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_LATE_FLOW_METRICS_IS_LOADING, payload: true });
        await getLateFlowMetricsData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_LATE_FLOW_METRICS_DATA, payload: json, runid: runid });
            else dispatch({ type: GET_LATE_FLOW_METRICS_DATA, payload: undefined, runid: runid });
            dispatch({ type: SET_LATE_FLOW_METRICS_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_LATE_FLOW_METRICS_IS_LOADING, payload: false });
            dispatch({ type: GET_LATE_FLOW_METRICS_DATA, payload: undefined, runid: runid });
        }).finally(() => {
            dispatch({ type: SET_LATE_FLOW_METRICS_IS_LOADING, payload: false });
        });
    };
}

export const getToolNoisePlots = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_TOOL_NOISE_PLOTS_IS_LOADING, payload: true });
        await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_TOOL_NOISE_PLOTS, payload: { plot1: result[0], plot2: result[1], plot3: result[2], plot4: result[3] }, runid: runid });
                    dispatch({ type: SET_TOOL_NOISE_PLOTS_IS_LOADING, payload: false });
                }
            }
            catch {
                dispatch({ type: GET_TOOL_NOISE_PLOTS, payload: undefined, runid: runid });
                dispatch({ type: SET_TOOL_NOISE_PLOTS_IS_LOADING, payload: false });
            }
        }).catch(() => {
            dispatch({ type: GET_TOOL_NOISE_PLOTS, payload: undefined, runid: runid });
            dispatch({ type: SET_TOOL_NOISE_PLOTS_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_TOOL_NOISE_PLOTS_IS_LOADING, payload: false });
        });
    };
}

export const getNoiseAndContextDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, payload: true });
        await getNoiseAndContextData(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_TOOL_NOISE_TABLE_DATA, payload: json, runid: runid });
            else dispatch({ type: GET_TOOL_NOISE_TABLE_DATA, payload: undefined, runid: runid });
            dispatch({ type: SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, payload: false });
            dispatch({ type: GET_TOOL_NOISE_TABLE_DATA, payload: undefined, runid: runid });
        }).finally(() => {
            dispatch({ type: SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, payload: false });
        });
    };
}

export const setCustomFilters = (data) => {
    return async dispatch => {
        dispatch({ type: SET_CUSTOM_FILTERS, payload: data });
    };
}

export const setCustomFiltersApplied = (isApplied) => {
    return async dispatch => {
        dispatch({ type: SET_CUSTOM_FILTERS_APPLIED, payload: isApplied });
    };
}

export const setRunListDataFiltered = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_DATA_FILTERED, payload: data });
    };
}

export const setRunListData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_DATA, payload: data });
    };
}

export const setRunListDataSearched = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_DATA_SEARCHED, payload: data });
    };
}

export const setRunListDataSearchedAMP = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_DATA_SEARCHED_AMP, payload: data });
    };
}

export const setRunListDownloadData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_DOWNLOAD_DATA, payload: data });
    };
}

export const setRunListAMPDownloadData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_LIST_AMP_DOWNLOAD_DATA, payload: data });
    };
}

export const setIsAllRunListDataShown = (isAll) => {
    return async dispatch => {
        dispatch({ type: SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100, payload: isAll });
    };
}

export const saveAnalysisCommentData = (runid, data, runlist, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        dispatch({ type: SET_ANALYSIS_COMMENT_DATA_SAVED, payload: false });
        await postAnalysisCommentData(runid, data, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: SET_EDIT_MODE, payload: false });

            try {
                if (runlist !== undefined) {
                    let objIndex;
                    let newRun;
                    let objRun;
                    if (runid.includes('_')) {
                        newRun = runid.split('_')[0];
                        objIndex = runlist.findIndex((obj => obj.runid === newRun));
                        objRun = runlist.find((obj => obj.runid === newRun));
                        if (objIndex !== undefined && objIndex !== -1) {
                            runlist[objIndex].analysiscomment = data.data;
                            if (objRun.subRows && objRun.subRows.length > 0) {
                                for (let i = 0; i < objRun.subRows.length; i++) {
                                    runlist[objIndex].subRows[i].analysiscomment = data.data;
                                }
                            }
                            dispatch({ type: LOAD_RUN_LIST_DATA, payload: runlist });
                        }
                    } else {
                        objIndex = runlist.findIndex((obj => obj.runid === runid));
                        if (objIndex !== undefined) {
                            runlist[objIndex].analysiscomment = data.data;
                            dispatch({ type: LOAD_RUN_LIST_DATA, payload: runlist });
                        }
                    }
                }
            }
            catch {
                dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
                dispatch({ type: SET_EDIT_MODE, payload: false });
            }

            dispatch({ type: SET_ANALYSIS_COMMENT_DATA_SAVED, payload: true });
        }).catch(() => {
            dispatch({ type: SET_ANALYSIS_COMMENT_DATA_SAVED, payload: false });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: SET_EDIT_MODE, payload: false });
        });
    };
}

export const saveDetailsData = (runid, data, runlist, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        dispatch({ type: SET_DETAILS_DATA_SAVED, payload: false });
        await postDetailsData(runid, data, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: SET_EDIT_MODE, payload: false });
            try {
                if (runlist !== undefined) {
                    let objIndex = runlist.findIndex((obj => obj.runid === runid));
                    if (objIndex !== undefined && objIndex !== -1) {
                        runlist[objIndex].details = data.data;
                        dispatch({ type: LOAD_RUN_LIST_DATA, payload: runlist });
                    }
                }
            }
            catch { }
            dispatch({ type: SET_DETAILS_DATA_SAVED, payload: true });
        }).catch(() => {
            dispatch({ type: SET_ALERT, payload: { errorMessage: `Unable to edit the Details field for runid: ${runid}. Run is still in progress. Changes was not saved.`, errorCode: 'Warning', errorType: 'warning' } });
            dispatch({ type: SET_DETAILS_DATA_SAVED, payload: false });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: SET_EDIT_MODE, payload: false });
        });
    };
}

export const setAnalysisCommentDataSaved = (isSaved) => {
    return async dispatch => {
        dispatch({ type: SET_ANALYSIS_COMMENT_DATA_SAVED, payload: isSaved });
    };
}

export const setDetailsDataSaved = (isSaved) => {
    return async dispatch => {
        dispatch({ type: SET_DETAILS_DATA_SAVED, payload: isSaved });
    };
}

export const setIsAuthenticated = (data) => {
    return async dispatch => {
        dispatch({ type: SET_ISAUTHENTICATED, payload: data });
    };
}

export const logOutCurrentUser = () => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        await AuthAPI.logOut().then(() => {
            dispatch({ type: LOGOUT_CURRENT_USER, payload: undefined });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        });
    };
}

export const resetState = () => {
    return async dispatch => {
        dispatch({ type: RESET_STATE });
    };
}

export const setEditMode = (isInEditMode) => {
    return async dispatch => {
        dispatch({ type: SET_EDIT_MODE, payload: isInEditMode });
    };
}

export const setNumberOfCustomFiltersApplied = (number) => {
    return async dispatch => {
        dispatch({ type: SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED, payload: number });
    };
}

export const setSearchTerm = (searchWords, searchColumn) => {
    return async dispatch => {
        dispatch({ type: SET_SEARCH_TERM, payload: { searchWords, searchColumn } });
    };
}

export const setSearchTermAMP = (searchWords, searchColumn) => {
    return async dispatch => {
        dispatch({ type: SET_SEARCH_TERM_AMP, payload: { searchWords, searchColumn } });
    };
}

export const setEditAllowed = (user) => {
    return async dispatch => {
        dispatch({ type: IS_EDIT_ALLOWED, payload: user });
    };
}

export const setErrorPlotLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_ERROR_PLOT_IS_LOADING, payload: isLoading });
    };
}

export const setReadLengthPlotLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_READ_LENGTH_PLOT_IS_LOADING, payload: isLoading });
    };
}

export const getReadLengthPlots = (runid, camera, jwt) => {
    return async dispatch => {
        let h5Plot1 = getH5ReadLengthPlotData(runid, 'count_of_reads_lengths_barcode_TT', camera, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let h5Plot2 = getH5FlowErrorPlotData(runid, 'flow_error_TT', camera, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let h5Plot3 = getH5ReadLengthPlotData(runid, 'count_of_reads_lengths', camera, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        Promise.all([h5Plot1, h5Plot2, h5Plot3]).then(results => {
            try {
                let result = { plot1: undefined, plot2: undefined, plot3: undefined };
                if (results !== undefined && results.length >= 2) {
                    if (results[0] !== undefined) result.plot1 = results[0];
                    if (results[1] !== undefined) result.plot2 = results[1];
                    if (results[2] !== undefined) result.plot3 = results[2];
                }
                dispatch({ type: GET_READ_LENGTH_PLOTS, runid: runid, camera: camera, payload: result });
            }
            catch {
                dispatch({ type: GET_READ_LENGTH_PLOTS, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined, plot3: undefined } });
            }
        }).catch(() => dispatch({ type: GET_READ_LENGTH_PLOTS, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined, plot3: undefined } }));

    };
}

export const setReadLengthPlotsByCameraValue = (camera) => {
    return async dispatch => {
        dispatch({ type: SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE, payload: camera });
    };
}

export const setErrorPlotsByCameraValue = (camera) => {
    return async dispatch => {
        dispatch({ type: SET_ERROR_PLOTS_BY_CAMERA_VALUE, payload: camera });
    };
}

export const getMedianRSQPlots = (runid, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: true });
        await getH5MedianRSQPlotData(runid, 'wafer_map_rsq', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            dispatch({ type: GET_RSQ_PLOT, runid: runid, camera: camera, payload: { h5 } });
            dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: GET_RSQ_PLOT, runid: runid, camera: camera, payload: { undefined } });
            dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: false });
        });
    };
}

export const setRSQPlotLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: isLoading });
    };
}

export const setPolyPlotsByCameraValue = (value) => {
    return async dispatch => {
        dispatch({ type: SET_POLY_PLOTS_BY_CAMERA_VALUE, payload: value });
    };
}

export const getPolyPlots = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_POLY_PLOTS_IS_LOADING, payload: true });
        let png = getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    return { plot1: result[0], plot2: result[1], };
                }
                else return undefined;
            }
            catch {
                return undefined;
            }
        }).catch(() => {
            return undefined;
        });

        let h5 = getH5PolyPlotDataAsync(runid, camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json;
            else return undefined;
        }).catch(() => undefined);

        Promise.all([png, h5]).then(results => {
            try {
                let result = {};
                if (results !== undefined && results.length >= 2) {
                    if (results[1] !== undefined) {
                        if (Object.keys(results[1].plot1).length !== 0 || Object.keys(results[1].plot2).length !== 0 || Object.keys(results[1].plot3).length !== 0) {
                            result.data = results[1];
                            result.type = 'h5';
                        }
                        else if (results[0] !== undefined) {
                            result.data = results[0];
                            result.type = 'png';
                        }
                    }
                    else if (results[0] !== undefined) {
                        result.data = results[0];
                        result.type = 'png';
                    }
                }
                dispatch({ type: GET_POLY_PLOTS, runid: runid, camera: camera, payload: result });
                dispatch({ type: SET_POLY_PLOTS_IS_LOADING, payload: false });
            }
            catch {
                dispatch({ type: GET_POLY_PLOTS, runid: runid, camera: camera, payload: undefined });
            }
        }).catch(() => dispatch({ type: GET_POLY_PLOTS, runid: runid, camera: camera, payload: undefined }))
            .finally(() => dispatch({ type: SET_POLY_PLOTS_IS_LOADING, payload: false }));
    };
}

export const getFWHMPlots = (runid, imgName, jwt) => {
    return async dispatch => {
        await getPNGData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_FWHM_PLOTS, payload: result, runid: runid });
                }
                else dispatch({ type: GET_FWHM_PLOTS, payload: undefined, runid: runid });
            }
            catch {
                dispatch({ type: GET_FWHM_PLOTS, payload: undefined, runid: runid });
            }
        }).catch(() => {
            dispatch({ type: GET_FWHM_PLOTS, payload: undefined, runid: runid });
        });
    };
}

export const setPolyPlotLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_POLY_PLOTS_IS_LOADING, payload: isLoading });
    };
}

export const setRunSummaryDensityPlotRenderData = (runid, camera, flow, data) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA, payload: data, runid: runid, camera: camera, flow: flow });
    };
}

export const setRunSummaryDensityPlotIsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING, payload: isLoading, });
    };
}

export const getRunSummaryDensityPlotDataAsync = (runid, flow, instrId, jwt) => {
    const isAnalysis = runid.includes('.ver') ? runid.split('.')[0] : runid
    return async dispatch => {
        dispatch({ type: SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING, payload: true });
        let tileArea = getTileAreaAsync(isAnalysis, instrId, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(tileArea => {
            return tileArea;
        }).catch(() => {
            return undefined;
        });

        let data = getHistogramPlotsDataAll(isAnalysis, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json && json.length) return json;
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        Promise.all([tileArea, data]).then(results => {
            if (results !== undefined && results.length >= 2) {
                if (results[0] !== undefined) dispatch(setTileArea(results[0], runid, instrId));
                if (results[1] !== undefined) dispatch({ type: GET_RUN_SUMMARY_DENSITY_PLOT_DATA, payload: results[1], runid: Utils.getRunIdWithoutCamera(runid), flow: flow })
                else dispatch({ type: GET_RUN_SUMMARY_DENSITY_PLOT_DATA, payload: undefined, runid: Utils.getRunIdWithoutCamera(runid), flow: flow });
            }
        }).catch(() => { })
            .finally(() => {
                dispatch({ type: SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING, payload: false });
            });
    };
}

export const setRunSummaryDensityPlotByCameraValue = (value) => {
    return async dispatch => {
        dispatch({ type: RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE, payload: value });
    };
}

export const setVersion = (value) => {
    return async dispatch => {
        dispatch({ type: SET_VERSION, payload: value, });
    };
}

export const setBeadsFilteringPlotIsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_BEADS_FILTERING_PLOTS_IS_LOADING, payload: isLoading });
    };
}


export const setReadsFilteringPlotByCameraValue = (value) => {
    return async dispatch => {
        dispatch({ type: READS_FILTERING_PLOT_BY_CAMERA_VALUE, payload: value });
    };
}

export const setFMetricsDataIsLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: SET_FMETRICS_DATA_IS_LOADING, payload: isLoading });
    };
}

export const geCoveragePlotsData = (runid, imgName, jwt) => {
    return async dispatch => {
        dispatch({ type: GET_COVERAGE_PLOTS_DATA_IS_LOADING, payload: true });
        await getJSONData(runid, imgName, false, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_COVERAGE_PLOTS_DATA, payload: json });
        }).catch(() => { }).finally(() => {
            dispatch({ type: GET_COVERAGE_PLOTS_DATA_IS_LOADING, payload: false });
        });
    };
}

export const getChartViewPlotsData = (start_date, end_date, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_CHART_VIEW_PLOTS_DATA_IS_LOADING, payload: true });
        await getRunDataByDate(start_date, end_date, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_CHART_VIEW_PLOTS_DATA, payload: json });
        }).catch(() => { }).finally(() => {
            dispatch({ type: SET_CHART_VIEW_PLOTS_DATA_IS_LOADING, payload: false });
        });
    };
}

export const getBaseQualityBerPerFlowPlot = (runid, imgName, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getPNGData(runid, imgName, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            try {
                if (json && json.length > 0) {
                    let result = [];
                    json.map(m => {
                        let buffer = m.data;
                        let binary = '';
                        let bytes = [].slice.call(new Uint8Array(buffer));
                        bytes.forEach((b) => binary += String.fromCharCode(b));
                        result.push(window.btoa(binary));
                    });
                    dispatch({ type: GET_BASE_QUALITY_BER_PER_FLOW_PLOT, payload: result[0], runid: runid });
                }
            }
            catch {
                dispatch({ type: GET_BASE_QUALITY_BER_PER_FLOW_PLOT, payload: undefined, runid: runid });
            }
        }).catch(() => {
            dispatch({ type: GET_BASE_QUALITY_BER_PER_FLOW_PLOT, payload: undefined, runid: runid });
        });
    };
}

export const setAlert = (errorMessage, errorCode, errorType) => {
    return async dispatch => {
        dispatch({ type: SET_ALERT, payload: { errorMessage: errorMessage, errorCode: errorCode, errorType: errorType } });
    };
}

export const clearAlert = () => {
    return async dispatch => {
        dispatch({ type: CLEAR_ALERT, payload: { errorMessage: '', errorCode: '', errorType: '' } });
    };
}

export const setUnifiedRowSelected = (isSelected) => {
    return async dispatch => {
        dispatch({ type: SET_IS_UNIFIED_ROW_SELECTED, payload: isSelected });
    };
}

export const getHomoIndelError = (runid, jwt) => {
    return async dispatch => {
        await getHomoIndelErrorAsync(runid, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(json => {
            if (json) dispatch({ type: GET_HOMO_INDEL_ERROR, payload: json });
            else dispatch({ type: GET_HOMO_INDEL_ERROR, payload: undefined });
        }).catch(() => dispatch({ type: GET_HOMO_INDEL_ERROR, payload: undefined }));
    };
}

export const addPlotlyPlotToList = (name) => {
    return async dispatch => {
        dispatch({ type: ADD_PLOTLY_PLOT_TO_LIST, payload: name });
    };
}

export const getFWHMCSVData = (runId, camera, jwt) => {
    return async dispatch => {
        await getFWHMCSVDataAsync(runId, camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_FWHM_CSV_DATA, payload: json });
        }).catch(() => { });
    };
}

export const setByCameraValueGlobal = (value) => {
    return async dispatch => {
        dispatch({ type: SET_BY_CAMERA_VALUE, payload: value });
    };
}

export const setShowFrozenHeader = (show) => {
    return async dispatch => {
        dispatch({ type: SET_SHOW_FROZEN_HEADER, payload: show });
    };
}

export const setValidData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_VALID_DATA, payload: data });
    };
}

export const setValidH5PlotData = (data) => {
    return async dispatch => {
        dispatch({ type: SET_H5PLOTS_VALID_DATA, payload: data });
    };
}

export const setValidDataStatus = (status) => {
    return async dispatch => {
        dispatch({ type: VALIDATION_STATUS, payload: status });
    };
}

export const setValidDataLoading = (isLoading) => {
    return async dispatch => {
        dispatch({ type: IS_VALID_DATA_LOADING, payload: isLoading });
    }
}
export const setJWT = (jwt) => {
    return async dispatch => {
        dispatch({ type: SET_JWT, payload: jwt });
    };
}

export const setJWTExp = (exp) => {
    return async dispatch => {
        dispatch({ type: SET_JWT_EXP, payload: exp });
    };
}

export const getSingleRunValidatedDataAsync = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: IS_VALID_DATA_LOADING, payload: true });
        await getRunValidatedDataByRunId(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json?.length > 0) dispatch({ type: SINGLE_VALID_RUN_DATA, payload: json });
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => {
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
            dispatch({ type: VALIDATION_STATUS, payload: false });
        }).finally(() => {
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
        });
    };
}

export const getRunDataAllGroupedValidatedAsync = (numFlows, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: true });
        await getRunGroupedAllValidated(numFlows, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            let sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort(function (a, b) {
                return new Date(b.startdatetime) - new Date(a.startdatetime);
            });
            dispatch({ type: LOAD_VALID_DATA, payload: sorted });
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: SET_IS_RUN_DATA_LOADING, payload: false });
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
        });
    };
}

export const getAMPDataValidAsync = (jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_AMP_DATA_LOADING, payload: true });
        await getAMPValidatedData(jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            const sorted = json.filter(f => f.startdatetime !== undefined && f.startdatetime !== null).sort((a, b) => new Date(b.startdatetime) - new Date(a.startdatetime));
            dispatch({ type: LOAD_VALID_AMP_DATA, payload: sorted });
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: IS_VALID_DATA_LOADING, payload: false });
        });
    };
}

export const getPolyPlotsValidated = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_POLY_PLOTS_IS_LOADING, payload: true });
        await getH5PolyPlotDataValidatedAsync(runid, camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json?.length > 0) {
                dispatch({ type: LOAD_H5PLOT_VALID_DATA, payload: json });
                dispatch({ type: VALIDATION_STATUS, payload: true });
            }
            //else dispatch({type: LOAD_H5PLOT_VALID_DATA, payload: undefined});
        }).catch(() => dispatch({ type: IS_VALID_DATA_LOADING, payload: false }))
            .finally(() => dispatch({ type: IS_VALID_DATA_LOADING, payload: false }));
    };
}


export const getFwhmValidated = (runid, jwt) => {
    return async dispatch => {
        dispatch({ type: IS_VALID_DATA_LOADING, payload: true });

        await getFwhmDataValidatedAsync(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) {
                dispatch({ type: FWHM_VALID_RUN_DATA, payload: json });
                dispatch({ type: VALIDATION_STATUS, payload: true });
            }
            else dispatch({ type: FWHM_VALID_RUN_DATA, payload: undefined });
        }).catch(() => dispatch({ type: IS_VALID_DATA_LOADING, payload: false }))
            .finally(() => dispatch({ type: IS_VALID_DATA_LOADING, payload: false }));
    };
}

export const getRunConfigTableDataAsyncValidated = (runid, camera, flow, jwt) => {
    return async dispatch => {
        let rc = getRunDataAsyncValidated(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let pv = getPipeLineDataAsyncValidated(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let gr = getGitRevDataAsyncValidated(runid, flow, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let am = getAnalysisMetaDataAsyncValidated(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let v = getVersionsDataAsyncValidated(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        let rm = getReadPeadsFilteringDataAsyncValidated(runid, jwt).then(response => {
            return !response.ok ? undefined : response.json();
        }).then(json => {
            if (json) return json;
            return undefined;
        }).catch(() => undefined);

        // let rsq = getMedianRSQValidated(runid, camera, jwt).then(response => {
        //     return !response.ok ? undefined : response.json();
        // }).then(json => {
        //     if (json) return json;
        //     return undefined;
        // }).catch(() => undefined);



        Promise.all([rc, pv, gr, am, v, rm]).then(results => {
            try {
                if (results !== undefined && results.length >= 6) {
                    let result = [];
                    if (results[0] !== undefined && results[0].length > 0) {
                        result.push({ run_data: results[0] });
                    }
                    if (results[1] !== undefined && results[1].length > 0) {
                        result.push({ pipe_line: results[1] });
                    }
                    if (results[2] !== undefined && results[2].length > 0) {
                        result.push({ git_revs: results[2] })
                    }
                    if (results[3] !== undefined && results[3].length > 0) {
                        result.push({ analysis_metadata: results[3] })
                    }
                    if (results[4] !== undefined && results[4].length > 0) {
                        result.push({ version: results[4] })
                    }
                    if (results[5] !== undefined && results[5].length > 0) {
                        result.push({ reads_peads: results[5] })
                    }
                    // if (results[6] !== undefined && results[6].length > 0) {
                    //     result.push({median_rsq: results[6]})
                    // }
                    dispatch({ type: GET_RUN_CONFIGURATION_VALIDATED_DATA, payload: result, runid: runid, flow: flow });
                    dispatch({ type: VALIDATION_STATUS, payload: true });
                }
                else dispatch({ type: GET_RUN_CONFIGURATION_VALIDATED_DATA, payload: undefined, runid: runid, flow: flow });
            }
            catch (e) {
                dispatch({ type: GET_RUN_CONFIGURATION_VALIDATED_DATA, payload: undefined, runid: runid, flow: flow });
            }
        }).catch(() => dispatch({ type: GET_RUN_CONFIGURATION_VALIDATED_DATA, payload: undefined, runid: runid, flow: flow }));
    };
}

export const getErrorPlotsValidated = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        let h5Plot1 = getH5BerHistPlotDataValidated(runid, 'ber_per_read_hist', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let h5Plot2 = getH5BerFlowPlotDataValidated(runid, 'ber_per_flow', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        Promise.all([h5Plot2, h5Plot1]).then(results => {
            let result = { plot1: undefined, plot2: undefined };
            if (results !== undefined && results.length > 0) {
                if (results[0] !== undefined) result.plot2 = results[0];
                if (results[1] !== undefined) result.plot1 = results[1];
            }
            dispatch({ type: GET_ERROR_PLOTS_VALIDATED, runid: runid, camera: camera, payload: result });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => dispatch({ type: GET_ERROR_PLOTS_VALIDATED, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined } }));
    };
}

export const getReadLengthValidatedData = (runid, camera, jwt) => {
    return async dispatch => {
        let h5Plot1 = getH5ReadLengthPlotDataValidated(runid, 'count_of_reads_lengths_barcode_TT', camera, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        let h5Plot2 = getH5FlowErrorPlotDataValidated(runid, 'flow_error_TT', camera, jwt).then(response => {
            return response.ok ? response.json() : undefined;
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        Promise.all([h5Plot1, h5Plot2]).then(results => {
            let result = { plot1: undefined, plot2: undefined };
            if (results !== undefined && results.length >= 2) {
                if (results[0] !== undefined) result.plot1 = results[0];
                if (results[1] !== undefined) result.plot2 = results[1];
            }
            dispatch({ type: GET_VALIDATED_READ_LENGTH_PLOTS, runid: runid, camera: camera, payload: result });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => dispatch({ type: GET_VALIDATED_READ_LENGTH_PLOTS, runid: runid, camera: camera, payload: { plot1: undefined, plot2: undefined } }));

    };
}

export const getPhasingTableValidatedData = (runid, jwt) => {
    return async dispatch => {
        await getPhasingDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_PHASING_VALIDATED_DATA, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => {
            dispatch({ type: GET_PHASING_VALIDATED_DATA, payload: undefined });
        });
    };
}

export const getReadsFilteringPlotsValidated = (runid, imgName, isRunBarcoded, camera, jwt) => {
    return async dispatch => {
        let h5Data = getH5ReadsFilteringPlotDataValidated(runid, 'reads_counting_data_passed_quality', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            return h5;
        }).catch(() => {
            return undefined;
        });

        Promise.all([h5Data]).then(results => {
            let result;
            if (results !== undefined && results.length > 0) {
                if (results[0] !== undefined) result = results[0];
            }
            dispatch({ type: GET_READS_FILTERING_PLOTS_VALIDATED, runid: runid, payload: result, camera: camera });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => {
            dispatch({ type: GET_READS_FILTERING_PLOTS_VALIDATED, runid: runid, payload: undefined, camera: camera });
        });
    };
}

export const getMedianRSQValidated = (runid, camera, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_RSQ_PLOT_IS_LOADING, payload: true });
        await getH5MedianRSQPlotDataValidated(runid, 'wafer_map_rsq', camera, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(h5 => {
            dispatch({ type: GET_RSQ_PLOT_VALIDATED, runid: runid, camera: camera, payload: h5 });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => {
            dispatch({ type: GET_RSQ_PLOT_VALIDATED, runid: runid, camera: camera, payload: { undefined } });
        });
    };
}

export const getBarcodeStatisticsTableDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getBarcodeStatisticsTableDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BARCODE_STATISTICS_VALIDATED, payload: json, runid: runid });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => {
            dispatch({ type: GET_BARCODE_STATISTICS_VALIDATED, payload: undefined, runid: runid });
        });
    };
}

export const getClumpingsTableValidated = (runid, jwt) => {
    return async dispatch => {
        await getClumpingDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_CLUMPINGS_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getSignalsLinearityTableDataValidated = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getLinearityDataAsyncValidated(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_LINEARITY_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getBeadsFilteringTableDataValidated = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        await getBeadsFilteringDataAsyncValidated(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BEADS_FILTERING_VALIDATED, payload: json, runid: runid });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getBERTableDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getBerDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BER_TABLE_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getBERMERTableDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getHmerDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_BER_HMER_TABLE_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getRLQTableDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getRLQDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_RLQ_TABLE_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getFMetricsTableDataValidated = (runid, isRunBarcoded, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_FMETRICS_DATA_IS_LOADING, payload: true });
        await getFMetricsDataAsyncValidated(runid, isRunBarcoded, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_FMETRICS_TABLE_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getQTableDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getQTableDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_Q_TABLE_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getSignalPlotsDataValidated = (runid, jwt) => {
    return async dispatch => {
        await getSignalPlotsDataAsyncValidated(runid, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: GET_SIGNAL_DATA_VALIDATED, payload: json });
            dispatch({ type: VALIDATION_STATUS, payload: true });
        }).catch(() => { });
    };
}

export const getRunInfoData = (jwt) => {
    return async dispatch => {
        await getRunInfoDataAsync(jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) {
                let result = json.length;
                dispatch({ type: GET_RUNINFO_DATA, payload: result });
            }
        }).catch(() => { });
    };
}

export const getRunInfoLengthData = (jwt) => {
    return async dispatch => {
        await getRunInfoLengthDataAsync(jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) dispatch({ type: GET_RUNINFO_DATA, payload: json });
        }).catch(() => { });
    };
}

export const getDiskSpacData = (jwt) => {
    return async dispatch => {
        await getDiskSpaceDataAsync(jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) {
                let availableStorage = json?.availableStorage;
                let spaceValue = parseFloat(availableStorage.split(' ')[0]);
                let spaceUnit = availableStorage.split(' ')[1];

                let result;
                if (spaceUnit === 'TB') {
                    // Convert TB to GB (1 TB = 1024 GB)
                    result = Math.floor(spaceValue * 1024 / 2);
                } 
                else if (spaceUnit === 'GB') {
                    result = Math.floor(spaceValue / 2);
                } 
                else if (spaceUnit === 'MB') {
                    // Convert MB to GB (1 GB = 1024 MB)
                    result = Math.floor(spaceValue / 1024 / 2);
                }

                // if (result < 50) {
                //     dispatch({ type: SET_ALERT_STORAGE_RUN, payload: true });
                // }
                dispatch({ type: GET_DISKSPACE, payload: result });
            }
        }).catch(() => { });
    };
}

export const setCustomStorageValues = (values) => {
    return async dispatch => {
        dispatch({ type: SET_CUSTOM_STORAGE, payload: values });
    };
};

export const setAlertStorageRun = (status) => {
    return async dispatch => {
        dispatch({ type: SET_ALERT_STORAGE_RUN, payload: status });
    };
}

export const getDeleteStatusByJobId = async (jobId, jwt) => {
    try {
        const statusResponse = await getDeletionStatusAsync(jobId, jwt);
        const statusRes = await statusResponse.json();
        return statusRes;
    }
    catch (error) {
        console.error(error)
    }
}

export const deleteRunsApply = async (data, jwt) => {

    localStorage.setItem("delete_run_request_status", true);
    // dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: true });

    try {
        const response = await deleteRunsApplyDataAsync(data, jwt);
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const res = await response.json();

        return res;
    } 
    catch (error) {
        console.error(error)
        localStorage.setItem("delete_run_request_status", false);
        //   dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: false });
    }
};

export const calculcateStorageFreeSpace = (data, jwt) => {
    return async dispatch => {
        try {
            const parseData = JSON.parse(data);
            const response = await getCalculatedFreeSpaceeDataAsync(parseData, jwt);
            if (!response.ok) throw Error(response.statusText);

            const res = await response.json();
            let result = Object.keys(res).length > 0 ? res.n : undefined;

            dispatch({ type: CALCULATED_CUSTOM_STORAGE_SPACE, payload: result });
        } 
        catch (error) {
            dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: error.errorMessage });
        }
    }
}

export const setBarcodeDetailsButton = (data) => {
    return async dispatch => {
        dispatch({ type: ACTIVE_BARCODE_TABLE_BUTTON, payload: data });
    }
}

export const addPlotlyURIList = (data) => {
    return async dispatch => {
        dispatch({ type: ADD_PLOTLY_URI_LIST, payload: data });
    };
}

export const setRunDetailsTablePdf = (runid, data) => {
    return async dispatch => {
        dispatch({ type: ADD_RUN_DETAILS_TABLE_PDF, payload: { data, runid } });
    }
}

export const setBeadsFilterTablePdf = (data) => {
    return async dispatch => {
        dispatch({ type: ADD_BEADS_FFILTER_TABLE_PDF, payload: data });
    }
}

export const setTimeZone = (timezone) => {
    return async dispatch => {
        dispatch({ type: TIME_ZONE_SELECTED, payload: timezone });
    };
}

export const getRunIdDataAllByDateAsync = (start_date, end_date, jwt) => {
    return async dispatch => {
        dispatch({ type: SET_IS_IMPORT_FIELD_LOADING, payload: true });
        await getRunIdDataByDate(start_date, end_date, jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            dispatch({ type: POPULATE_RUNIDS, payload: json });
            dispatch({ type: SET_IS_IMPORT_FIELD_LOADING, payload: false });
        }).catch(() => {
            dispatch({ type: POPULATE_RUNIDS, payload: [] });
            dispatch({ type: SET_IS_IMPORT_FIELD_LOADING, payload: false });
        }).finally(() => {
            dispatch({ type: SET_IS_IMPORT_FIELD_LOADING, payload: false });
        });
    };
}

export const setPopulateRuns = (data) => {
    return async dispatch => {
        dispatch({ type: POPULATE_RUNIDS, payload: data });
    };
}

export const deleteRunIdListData = (runIdsArray, jwt) => {
    return async dispatch => {
        localStorage.setItem("delete_run_request_status", true);
        dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: true });
        const requestData = { data: runIdsArray };
        try {
            const response = await deleteRunIdsDataAsync(requestData, jwt);
            if (!response.ok) {
                localStorage.setItem("delete_run_request_status", false);
                dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: false });
                throw Error(response.statusText);
            }

            const res = await response.json();

            if (res) {
                localStorage.setItem("delete_run_request_status", false);
                dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: false });
                sessionStorage.setItem('delete_complete_message', 'Data has been successfully deleted')
            }
        } 
        catch (error) {
            localStorage.setItem("delete_run_request_status", false);
            dispatch({ type: DELETE_RUN_REQUEST_STATUS, payload: false });
        }
    };
};

export const getPpmSeqReportList = (runid, barcode, jwt) => {
    return async dispatch => {
        try {
            const response = await getPpmSeqReportListDataAsync(runid, barcode, jwt);
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const json = await response.json();
            if (json) {
                dispatch({ type: GET_PPMSEQ_REPORT_LIST, payload: json });
            }
        } 
        catch (error) {
            console.error(`getPpmSeqReportList error: ${error}`);
        }
    };
};

export const setColumnType = (column) => {
    return async dispatch => {
        dispatch({ type: SET_COLUMN_TYPE, payload: column });
    };
}

export const setBarcodeCoverageBarcode = (barcode) => {
    return async dispatch => {
        dispatch({ type: SET_BARCODE_COVERAGE_BARCODE, payload: barcode });
    };
}

export const setExpandedRunId = (data) => {
    return async dispatch => {
        dispatch({ type: SET_EXPANDED_RUNID, payload: data });
    };
};

export const clearExpandedRunId = (runId) => {
    return async dispatch => {
        dispatch({ type: CLEAR_EXPANDED_RUNID, payload: runId });
    };
};
