export const PlotBarcodeCoveragePlot = (runid, csv, barcode) => {
    try {
        let data = [];

        //if (runid)
        runid = runid.split('_')[0];

        const getPlotColor = index => {
            switch (index) {
                case 0:
                    return '#3274a1';
                case 0:
                    return '#e1812c';
                case 1:
                    return '#e1812c';
                case 2:
                    return '#e1812c';
                case 3:
                    return '#e1812c';
                case 4:
                    return '#e1812c';
                case 5:
                    return '#3a923a';
                case 6:
                    return '#3a923a';
                case 7:
                    return '#3a923a';
                case 8:
                    return '#3a923a';
                case 9:
                    return '#3a923a';
                case 10:
                    return '#3a923a';
                case 11:
                    return '#3a923a';
                case 12:
                    return '#3a923a';
                case 13:
                    return '#3a923a';
                case 14:
                    return '#c03d3e';
                case 15:
                    return '#c03d3e';
                case 16:
                    return '#9372b2';
                case 17:
                    return '#9372b2';
                case 18:
                    return '#9372b2';
                case 19:
                    return '#9372b2';
                case 20:
                    return '#9372b2';
                case 21:
                    return '#9372b2';
                case 22:
                    return '#9372b2';
                case 23:
                    return '#9372b2';
                default:
                    break;
            }
        };

        csv.forEach((item) => {
            try {
                var trace = {
                    mode: "markers",
                    type: 'box',
                    name: item.genome,
                    marker: { color: getPlotColor(data.length) },
                    y: Object.values(item.data).filter((f, i) => !isNaN(f) && f != "Infinity" && i !== 2),
                    autobinx: true,
                    autobiny: true,
                    boxpoints: 'all',
                    text: ['min', '25pct', 'median', '75pct', 'max'],
                    hovertemplate: '%{text}: %{y}<extra></extra>',
                    pointpos: 0.1
                };
                data.push(trace);
            } catch (e) {
                console.log(e);
            }
        });


        let layout = {
            title: `GC bias per genome region<br> ${runid}: ${barcode}`,
            font: {
                //color: 'white'
            },
            xaxis: {
                ticks: 'outside',
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
                //tickangle: -90,
            },
            yaxis: {
                ticks: 'outside',
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                //autorange: true,
                range: [0, 2],
                automargin: true,
            },
            showlegend: false,
            shapes: [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 0.5,
                    x1: 1,
                    y1: 0.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1,
                    x1: 1,
                    y1: 1,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1.5,
                    x1: 1,
                    y1: 1.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2,
                    x1: 1,
                    y1: 2,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2.5,
                    x1: 1,
                    y1: 2.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
            ]
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}